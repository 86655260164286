import mixpanel from "mixpanel-browser";
import { dumpLogs, loggedSession } from "../hooks/dumpLogs";
import { Capacitor } from "@capacitor/core";
import { getStudentFromStorage } from "@helpers/migrateLogin";
class MixpanelEventClass {
    student = {};
    mathai_platform = "";
    activity_type = "CHALLENGE";
    query_param_keys: any = [];
    query_param_values: any = [];

    init() {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
            persistence: "localStorage",
            ignore_dnt: true,
            batch_requests: true,
            api_host: process.env.NEXT_PUBLIC_MIXPANEL_PROXY_URL,
        });
        this.mathai_platform = Capacitor.getPlatform();
    }
    identify(userId: string) {
        const student = JSON.parse(getStudentFromStorage() || "{}");
        if (student) {
            const other = JSON.parse(student?.other || "{}");
            this.student = {
                id: student.id,
                name: student.name,
                mobile: student.mobile,
                grade: student.class_name,
                type: student.type,
                parent_name: other?.parent_name,
                email: student.email,
                school_name: other?.school_name,
            };
        }

        mixpanel.identify(userId);
        let other = student?.other || {};
        try {
            other = JSON.parse(student?.other);
        } catch {}

        const params = new URLSearchParams(window.location.search);
        const restrictedParams = [
            "worksheet",
            "worksheet_standalone",
            "attempt",
            "lastAttempt",
            "showQuestion",
            "worksheet_id",
            "activity_id",
            "context",
            "chunk_preview",
            "progression_id",
            "progression_activity_id",
            "path_id",
            "variant_id",
            "block_id",
            "logs",
            "session_id",
            "user_id",
            "student_id",
            "view_id",
            "preview_id",
            "snapshot_type",
            "returnTo",
        ];

        params.forEach((value, key) => {
            if (restrictedParams.includes(key)) return;
            MixpanelEvent.addKeyValueArray(key, value);
        });

        mixpanel.people.set(
            {
                name: student.name,
                email: student.email,
                phone: student.mobile,
                grade: student.class_name,
                type: student.type,
                gender: student.gender,
                access_type: student.access_type,
                parent_name: other?.parent_name,
                maths_feeling: other?.maths_feeling,
                pervious_marks: other?.pervious_marks,
                // language: other?.language,
                competition_prep: other?.competition_prep,
                goals: other?.goals,
                goals_commitment: other?.goals_commitment,
                profile_picture: other.profile_picture,
                school_name: other?.school_name,
                profile_id: student.id,
            },
            (...e) => {
                if (e[0] !== 1)
                    dumpLogs({
                        action: "mixpanel_people_set_fail",
                        ...student,
                    });
                else
                    dumpLogs({
                        action: "mixpanel_people_set",
                        ...student,
                    });
            },
        );
    }

    defaultEvents = { utm_source: "" };
    track(event: string, data?: any) {
        const logged_session_id = loggedSession.logged_session_id;
        mixpanel.track(
            event,
            {
                ...this.defaultEvents,
                ...data,
                ...this.student,
                mathai_platform: this.mathai_platform,
                ...(logged_session_id ? { logged_session_id } : {}),
                activity_type: this.activity_type,
            },
            (...e) => {
                if (e[0] !== 1)
                    dumpLogs({
                        action: "mixpanel_track_fail",
                        ...this.student,
                        event,
                        data,
                    });
            },
        );
    }
    addKeyValueArray(key: string, value: string) {
        mixpanel.people.append("query_param_keys", key);
        mixpanel.people.append("query_param_values", value);
        this.query_param_keys.push(key);
        this.query_param_values.push(value);
    }
    getStudent() {
        return {
            distinct_id: mixpanel.get_distinct_id(),
            ...this.student,
        };
    }
}

export const MixpanelEvent = new MixpanelEventClass();
