import { FunctionComponent, useEffect, useState } from "react";
import { PersonalizeLayout } from "../view/PersonalizeLayout";
import MetaHead from "./MetaHead";
import ReactPlayer from "react-player";

const SplashScreen: FunctionComponent = (props: any) => {
    const { setShowSplash, metadataTags, showSplash } = props;
    useEffect(() => {
        setTimeout(() => {
            setShowSplash(false);
        }, 3000);
    }, []);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    return (
        <div
            className={`fixed left-0 top-0 ${
                !showSplash ? "hidden h-0 w-0" : "h-screen w-screen"
            } z-[1000] bg-white`}
        >
            <PersonalizeLayout containerStyle={{ height: "100vh" }}>
                <MetaHead metadataTags={metadataTags} />
                <div className="text-gray relative h-full w-full overflow-hidden bg-cornsilk text-center font-content text-[36px]">
                    <div className="absolute left-[calc(50%_-_110px)] top-[calc(50%_-_88.5px)] flex flex-col items-center justify-start gap-[32px]">
                        <div className="h-20 w-20 overflow-hidden rounded-[60px] border-4 border-[#ffde49]">
                            <ReactPlayer
                                playing={true}
                                url="https://cdn.homeworkapp.ai/assets/personalised-learning/Onboarding+%26+Homepage+videos+for+avatar/onboarding_v2/No+audio.mp4"
                                loop
                                muted
                                onPlay={() => {
                                    setIsVideoLoaded(true);
                                }}
                                playsinline={true}
                                width="72px"
                                height="72px"
                                style={{
                                    // width: "5rem",
                                    overflow: "hidden",
                                    borderRadius: "60px",
                                    // ...(!isVideoLoaded && {
                                    //     visibility: "hidden",
                                    // }),
                                }}
                            />
                        </div>
                        <div className="flex flex-col items-center justify-start gap-[4px]">
                            <div className="relative inline-block w-[220px] font-black">
                                <span>Math</span>
                                <span className="text-[#ffde49]">AI</span>
                            </div>
                            <div className="relative inline-block w-[220px] text-[16px] text-[#333]">
                                <p className="m-0">Your personal AI</p>
                                <p className="m-0">Math coach!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </PersonalizeLayout>
        </div>
    );
};

export default SplashScreen;
